@tailwind base;
@tailwind components;
@tailwind utilities;

.zoom-effect {
  animation: zoom 5s infinite alternate;
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.slide-from-left {
  animation: slide 3s linear infinite;
}

@keyframes slide {
  0% {
    transform: translateX(-1%);
  }
  100% {
    transform: translateX(1%);
  }
}

.slide-from-rigth {
  animation: slide-rigth 3s linear infinite;
}

@keyframes slide-rigth {
  0% {
    transform: translateX(1%);
  }
  100% {
    transform: translateX(-1%);
  }
}

.slide-from-head {
  animation: slide-head 3s linear infinite;
}

@keyframes slide-head {
  0% {
    transform: translateX(2%);
  }
  100% {
    transform: translateX(-2%);
  }
}


.zoom-effect-sky {
  animation: zoom-sky 10s infinite alternate;
}

@keyframes zoom-sky {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}